.panel {
  border: 9px solid $color-yellow;
  padding: 1em;
  font-size: 0.9em;
  margin-top: 3em;
  margin-bottom: 3em;

  @include media-breakpoint-down(sm) {
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  h1, h2, h3, h4, h5, h6 {
    &:first-child {
      margin-top: 0;
    }
  }

  h2 {
    @include largeHeadingText;
  }
}

.panel-blue {
  background-color: $color-blue-dark;
  color: $color-white;
  border: none;
  padding: 1.5em;
  a {
    color: $color-white;
    font-weight: bold;
    &:hover {
      color: $color-white;
    }
  }
}
