/* Generated by Font Squirrel (http://www.fontsquirrel.com) on December 12, 2014 */

//@font-face {
//  font-family: 'gapsans';
//  font-weight: 400;
//  font-style: normal;
//  src: url('../../vendor/gapsans/gapsans-webfont.eot');
//  src: url('../../vendor/gapsans/gapsans-webfont.eot?#iefix') format('embedded-opentype'),
//  url('../../vendor/gapsans/gapsans-webfont.woff2') format('woff2'),
//  url('../../vendor/gapsans/gapsans-webfont.woff') format('woff'),
//  url('../../vendor/gapsans/gapsans-webfont.ttf') format('truetype'),
//  url('../../vendor/gapsans/gapsans-webfont.svg#gap_sansregular') format('svg');
//}

@font-face {
  font-family: 'gapsans';
  font-weight: 600;
  font-style: normal;
  src: url('../../vendor/gapsans/gapsansbold-webfont.eot');
  src: url('../../vendor/gapsans/gapsansbold-webfont.eot?#iefix') format('embedded-opentype'),
  url('../../vendor/gapsans/gapsansbold-webfont.woff2') format('woff2'),
  url('../../vendor/gapsans/gapsansbold-webfont.woff') format('woff'),
  url('../../vendor/gapsans/gapsansbold-webfont.ttf') format('truetype'),
  url('../../vendor/gapsans/gapsansbold-webfont.svg#gap_sansbold') format('svg');
}
//
//@font-face {
//  font-family: 'gapsans';
//  font-weight: 800;
//  font-style: normal;
//  src: url('../../vendor/gapsans/gapsansblack-webfont.eot');
//  src: url('../../vendor/gapsans/gapsansblack-webfont.eot?#iefix') format('embedded-opentype'),
//  url('../../vendor/gapsans/gapsansblack-webfont.woff2') format('woff2'),
//  url('../../vendor/gapsans/gapsansblack-webfont.woff') format('woff'),
//  url('../../vendor/gapsans/gapsansblack-webfont.ttf') format('truetype'),
//  url('../../vendor/gapsans/gapsansblack-webfont.svg#gap_sansblack') format('svg');
//}
