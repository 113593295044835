.countdown {
  margin: 0 auto 2em  ;
  text-align: right;
  @include media-breakpoint-down(xs) {
    text-align: center;
  }
  .countdown-item {
    display: inline-block;
    text-align: center;
    width: 20%;
    margin-right: 0;
    margin-bottom: 0;

    &:last-child {
      margin-right: 0;
    }

    .value {
      display: block;
      height: 1.25em;
      font-size: 3rem;
      font-weight: bold;
      margin-left: 0;
      margin-bottom: 0;
    }
    .unit {
      display: block;
      font-size: 0.9em;
      font-weight: bold;
      text-transform: uppercase;
    }

    @include media-breakpoint-only(xs) {
      margin-right: 0;
      .value {
        font-size: 2.4em;
      }
      .unit {
        font-size: 0.7em;
      }
    }
  }
}
