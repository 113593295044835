.resblock {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $color-blue-darker;
  color: $color-white;
  display: none;

  .resblock-error-size, .resblock-error-orientation {
    display: none;
  }

  @media (max-width: 900px) and (orientation: portrait) {
    display: block;

    .resblock-error-orientation {
      display: block;
    }
  }

  @media (max-width: 900px) and (orientation: landscape),
         (max-height: 500px) and (orientation: landscape) {
    display: block;

    .resblock-error-size {
      display: block;
    }
  }

  .resblock-error {
    max-width: 80%;
    margin: 0 10%;
    padding-top: 10vh;
    text-align: center;
    line-height: 1.5;

    .resblock-error-main {
      font-size: 1.3rem;
    }
  }

  a {
    color: $color-white;
    font-weight: bold;
  }
}
