.readable {
  font-size: 1.25rem;
  line-height: 1.75rem;
  @include media-breakpoint-down(sm) {
    font-size: 1.1rem;
    line-height: 1.7rem;
  }

  @mixin columned {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    @include media-breakpoint-down(sm) {
      max-width: none;
      margin-left: 20px;
      margin-right: 20px;
    }
  }

  h1, h2, h3, h4, h5, h6, p, ul, ol, .lead, .panel {
    @include columned;
  }

  h1, h2, h3 {
    margin-top: 1.5em;
    margin-bottom: 0.75em;
  }

  h1 {
    font-size: 3rem ;
    @include media-breakpoint-down(sm) {
      font-size: 2.5rem;
    }
  }

  h2 {
    font-size: 2rem;
    @include media-breakpoint-down(sm) {
      font-size: 1.75rem;
    }
  }

  h3 {
    font-size: 1.5rem;
  }

  hr {
    border-top: 8px solid black;
    max-width: 4%;
    margin: 2em auto;
  }

  .lead {
    font-weight: 400;
    margin-bottom: 1em;
    font-size: 1.33em;
  }

  figure, .video, .app {
    @include columned;
    margin-top: 2em;
    margin-bottom: 2em;
    & > img, & > iframe {
      display: block;
      width: 100%;
      border: none;
    }

    & > figcaption, & > .caption {
      font-size: 0.8em;
      line-height: 1.5em;
      .credits {
        font-weight: bold;
        color: $color-gray;
        margin-top: -0.9em;
      }
    }

    &.large {
      max-width: 960px;
      @include media-breakpoint-down(lg) {
        max-width: 768px;
      }

      @include media-breakpoint-down(sm) {
        max-width: 100%;
        margin-left: 0;
        margin-right: 0;

        & > figcaption, & > .caption {
          margin-left: 20px;
          margin-right: 20px;
        }
      }
    }

    &.border {
      border: none !important;
      & > img, & > iframe {
        border: 2px solid $color-black;
        margin-bottom: 5px;
      }
    }
  }

  .panel {
    border: 9px solid $color-yellow;
    padding: 1em;
    font-size: 0.9em;
    margin-top: 3em;
    margin-bottom: 3em;

    @include media-breakpoint-down(sm) {
      max-width: 100%;
      margin-left: 0;
      margin-right: 0;
    }

    h1, h2, h3, h4, h5, h6 {
      &:first-child {
        margin-top: 0;
      }
    }

    h2 {
      @include largeHeadingText;
    }
  }

  .license {
    font-size: 0.9em;
  }
}
