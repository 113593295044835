.eggy {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 500px;
  z-index: $z-index-eggy;

  svg {
    width: 100%;
    height: 100%;
  }
}

.fixed-eggy {
  .eggy, &.eggy {
    position: fixed;
    height: 100%;
    z-index: $z-index-eggy;
  }
}
