.section {
  position: relative;
  margin-top: 8em;
}

.section-gray {
  background-color: #c4c4c480;
}

.section-border-t {
  border-top: 8px solid $color-black;
}

.section-splash {
  margin-top: 0;

  @include media-breakpoint-down(sm) {
    height: 100%;
  }
}

.scroll-arrow {
  display: block;
  height: 80px;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-top: 20px;
}

.trail {
  margin-bottom: 2em;
  margin-top: 2em;

  a {
    display: block;
    color: $color-blue-dark;
    &:hover {
      text-decoration: none;

      .trail-thumbnail {
        transform: scale(0.96);
      }
    }
  }

  .trail-action {
    @include largeHeadingText;
    font-size: 35px;
  }

  .trail-thumbnail {
    width: 100%;
    height: 0;
    padding-bottom: (340 / 740) * 100%;
    transition: transform 0.3s;

    img {
      display: block;
      width: 100%;
      height: auto;
      margin: 0;
    }
  }

  .trail-title {
    font-weight: 500;
    font-size: 20px;
  }
}

.tour-panel {
  display: block;
  background-color: $color-yellow;
  padding: 2em;

  h3 {
    @include largeHeadingText;
    margin-bottom: 2em;
  }
}

a.tour-panel {
  text-decoration: none;
  color: $color-black;
  &:hover {
    text-decoration: none;
    transform: scale(0.96);
  }
}

.venue-box {
  position: relative;
  padding-left: 3em;
  .fa {
    position: absolute;
    top: 0.3em;
    left: 0.4em;
    color: $color-blue-dark;
  }
}

.lang-switcher-bar {
  text-align: right;
  margin-top: 5px;

  .item {
    display: inline-block;
    font-weight: 500;
    margin-right: 1em;
    &:last-child {
      margin-right: 0;
    }
    color: $color-black;
  }
}

