.footer {
  background-color: #ffffff73;
  position: relative;
  padding: 2em 0;
  border-top: 2px solid black;
}

ul.sitemap {
  margin: 0;
  padding: 0;
  li {
    display: block;
    margin: 0.4em 0;
    padding: 0;
    list-style: none;

    a {
      color: $color-black;
    }
  }
}

.footer-legal {
  padding: 1em 0;
  background-color: $color-black;
  color: $color-white;
  @include neg;

  a {
    color: $color-white;
  }
}
