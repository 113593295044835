.news-item {
  font-size: 1.15rem;
  margin-bottom: 1em;

  &:last-child {
    margin-bottom: 0;
  }

  .date {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 0.2em;
  }
}

.page-news {
  $item-padding: 17px;
  $priory-border-width: 9px;
  .news-item {
    padding: $item-padding;

    &.news-item-priority {
      border: $priory-border-width solid #ffec02;
      padding: $item-padding - $priory-border-width;
      background-color: rgba(255, 255, 255, 0.6);
    }
  }
}
