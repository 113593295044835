$nav-height: 10vh;
$nav-width: 100vw;
$footer-height: 5vh;
$container-width: 100vw;
$container-height: 100vh - ($nav-height + $footer-height);
$nav-border-width: 3px;
$nav-font-size: 20px;

body.virtual-tour {

}

.tour-main {
  width: 100vw;
  height: 100vh;
  background-color: $color-white;
}

.tour-nav {
  position: relative;
  height: $nav-height;
  width: $nav-width;
  border-bottom: $nav-border-width solid $color-black;

  button {
    position: relative;
    border: none;
    margin: 0;
    padding: 10px;
    font-size: $nav-height * 0.5;
    background-color: transparent;

    &:focus {
      box-shadow: none;
      outline: none;
      border: 1px dotted $color-gray;
    }

    &.disabled {
      opacity: 0.5;
    }
  }

  .button-backward, .button-forward {
    &:after {
      content: "30s";
      display: block;
      font-size: 10px;
      position: absolute;
      top: 2px;
      width: 60%;
      text-align: center;
      pointer-events: none;
      line-height: 9vh;
      margin-top: -1.5em;
    }
  }

  .tour-nav-exit {
    position: relative;
    height: 100%;
    border-right: $nav-border-width solid $color-black;
    .arrow {
      display: inline-block;
      text-align: left;
      width: 50px;
      height: 100%;
      vertical-align: middle;
      font-size: $nav-font-size * 0.85;
      padding-right: 10px;
    }
    .logo {
      display: inline-block;
      width: 100px;
      height: 100%;
      background-image: url('../img/i-am-ai-logo-small.svg');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }

  .tour-chapter-sel {
    display: inline-block;
    vertical-align: top;
    width: calc(#{$nav-width} - 376px);
    height: 100%;
    & > button {
      height: 100%;
      font-size: $nav-font-size;
      padding-left: 1em;
      &:after {
        margin-left: 0.5em;
        vertical-align: 0.155em;
      }
    }
  }
}

.tour-screen {
  position: relative;
  width: $container-width;
  height: $container-height;
  background-color: $color-black;

  .iframe-wrapper {
    width: $container-width;
    height: (9 / 16) * $container-width;
    max-width: (16 / 9) * $container-height;
    max-height: $container-height;
    margin: 0 auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: none;
      background-color: $color-white;
    }
  }

  .image-wrapper {
    display: flex;
    width: $container-width;
    height: (9 / 16) * $container-width;
    max-width: (16 / 9) * $container-height;
    max-height: $container-height;
    margin: 0 auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $color-white;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    opacity: 0;
    transition: opacity 1s;

    img {
      display: block;
      max-width: 100%;
      max-height: 100%;
      height: auto;
    }

    &.visible {
      opacity: 1;
    }
  }
}

.tour-controls {
  border-left: $nav-border-width solid $color-black;
  display: inline-block;
  height: 100%;
  vertical-align: top;
  button {
    width: 50px;
    height: 100%;
    font-size: $nav-font-size * 0.85;
  }
}

.tour-footer {
  border-top: $nav-border-width solid $color-black;
}

.tour-guide {
  position: absolute;
  right: 15px;
  bottom: 15px;
  width: 40vh;
  height: 40vh;
  max-width: 250px;
  max-height: 250px;
  border-radius: 50%;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(white, black); // required for Safari
  transition: opacity 1s;
  opacity: 0;

  &.visible {
    opacity: 1;
  }

  &.button-mode {
    border: 8px solid $color-yellow;
  }

  .tour-guide-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    iframe {
      display: block;
      width: 100%;
      height: 100%;
      pointer-events: none;
    }
  }

  .tour-guide-play-button,
  .tour-guide-button {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: $color-white;
    transition: background-color 0.3s;

    &:hover {
      background-color: $color-yellow-light;
    }

    .tour-guide-play-button-text,
    .tour-guide-button-text {
      width: 80%;
      margin: 0 10%;
      text-align: center;
      font-size: 25px;
      font-weight: 500;
    }
  }

  .tour-guide-play-button-text {
    font-size: 1.6em;
    .fa-play {
      display: block;
      margin-bottom: 0.4em;
      margin-left: 0.1em;
      font-size: 2em;
    }
  }

  .tour-guide-loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    .lds-ellipsis {
      text-align: center;
    }
  }
}

.neural-numbers-component-page-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.vg-neural-numbers {
  .neural-numbers-component {
    width: 900px;
    .stage {
      overflow: hidden;
      flex: 0 1 240px;
      justify-content: center;
      margin-right: 0;
    }
    .stage-normalize,
    .stage-bars {
      flex: 0 1 0;
      transition: flex 2s;

      &.visible {
        flex: 0 1 240px;
      }
    }

    .stage-input {
      .input-placeholder {
        margin: 0;
        padding: 0;
        width: 60%;
        left: 20%;
      }
    }

    &.with-output.with-bars .stage-output {
      @include media-breakpoint-down(md) {
        display: flex !important;
      }
    }
  }
}

