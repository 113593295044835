$base-font-size: 16px;
$base-line-height: 1.3;

@mixin regularText {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}

@mixin largeHeadingText {
  @include gapSansBold;
  text-transform: uppercase;
}
