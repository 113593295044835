.tab-content-neural-numbers {
  position: relative;

  .tab-pane {
    position: absolute;
    top: 0;
    width: 100%;
    display: block !important;
    visibility: hidden;
    pointer-events: none;

    &.active {
      visibility: visible;
      pointer-events: all;
    }
  }
}
