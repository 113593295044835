html, body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

body {
  @include regularText;
  font-size: $base-font-size;
  line-height: $base-line-height;
}

h1 {
  @include largeHeadingText;
  margin-top: 1.5em;
  font-size: 3.5rem;
}

.author {
  margin-top: -1em;
  margin-bottom: 2em;
  font-size: 0.9em;
}

ul {
  margin: 0 0 1em;
  padding: 0 1em;
  li {
    margin-bottom: 0.1em;
  }
}

ul.files {
  list-style: none;
  padding: 0;

  li {
    list-style: none;
    padding: 0;
    margin-bottom: 1.4em;
  }
  a {
    display: inline-block;
    font-weight: 500;
    background-color: white;
    color: $color-black;
    padding: 0.2em 0.4em 0.3em 0.4em;

    i.fa {
      color: $color-blue;
      font-size: 2em;
      margin-right: 0.3em;
      margin-top: -0.4em;
    }
  }
}

a {
  color: $color-blue-dark;
  &:hover {
    color: $color-blue-dark;
  }
}

a.learnmore {
  font-weight: bold;
  font-size: 0.9em;
}

.btn-download {
  position: relative;
  background-color: $color-white-dark;
  font-size: 1em;
  font-weight: 500;
  padding-left: 4em;

  &:before {
    content: " ";
    display: block;
    width: 2em;
    height: 2em;
    position: absolute;
    margin-top: 0.4em;
    margin-left: -3em;
    background-image: url('../img/fontawesome/download-solid.svg');
    background-size: contain;
  }

  br {
    display: none;
  }
  small {
    display: block;
  }
}

h2.medium {
  @include largeHeadingText;
  font-size: 3rem;

  @include media-breakpoint-down(sm) {
    font-size: 2.5rem;
  }
}

h2.large {
  @include largeHeadingText;
  font-size: 3rem;
  margin-bottom: 1em;

  @include media-breakpoint-down(sm) {
    font-size: 2.5rem;
  }
}

h3.large {
  @include largeHeadingText;
  font-size: 2rem;
  margin-bottom: 1em;

  @include media-breakpoint-down(sm) {
    font-size: 1.7rem;
  }
}

.small-title {
  text-transform: uppercase;
  font-size: 1.3em;
}

.lead {
  font-weight: 500;
  font-size: 1.6rem;

  @include media-breakpoint-down(sm) {
    font-size: 1.4em;
  }
}

.large {
  font-size: 1.2em;
}

.bordered {
  border: 8px solid $color-white;
  padding: 0.5em 1em;
}

.btn-primary {
  background-color: $color-blue-dark;
  border-color: $color-blue-dark;
}

.btn-largesq {
  position: relative;
  text-align: left;
  background-color: $color-white;
  border-color: $color-gray;
  border-width: 5px;
  padding-left: 3em;
  margin-bottom: 0.5em;
  line-height: 1.2;
  .fa {
    position: absolute;
    top: 0.3em;
    left: 0.3em;
    color: $color-gray;
  }

  &:hover {
    .fa {
      color: $color-blue;
    }
  }
}

.panel {
  padding: 1em 2em;
}

.panel-gray {
  background-color: #c4c4c480;
}
