$navbar-height: 60px;
$navbar-border: 2px;
$navbar-padding-x: 15px;

.navbar {
  border-bottom: $navbar-border solid black;
  z-index: $z-index-navbar;

  a:focus, button:focus {
    background-color: #ececec;
    box-shadow: none;
    outline: none;
  }

  .navbar-brand {
    padding: 0;
    .logo {
      height: 42px;
      width: 142px;
    }
  }

  & > .dropdown {
    position: absolute;
    .dropdown-toggle {
      min-width: $navbar-height - $navbar-border;
      height: $navbar-height - $navbar-border;
      padding: 0;
      margin: 0;
      border-radius: 0;
    }

    & > .dropdown-menu {
      border: 1px solid black;
      border-top: none;
      border-radius: 0;
    }
  }

  .dropdown-divider {
    border-color: black;
  }

  .dropdown-item {
    &.active, &:active {
      background-color: #ececec;
      color: $color-black;
    }
  }

  .dropdown-trails {
    left: $navbar-padding-x;
    width: 300px;

    @include media-breakpoint-up(md) {
      width: 450px;
    }

    .dropdown-item {
      white-space: normal;
    }

    .dropdown-item-solo {
      font-weight: 500;
      font-size: 1.15em;
    }

    @include media-breakpoint-down(sm) {
      left: 0;
    }

    & > .dropdown-toggle {
      &:after {
        display: none;
      }

      .navbar-toggler-icon {
        background-image: url('../img/fontawesome/bars-solid.svg');
      }
    }

    & > .dropdown-menu {
      & > .dropdown-item {
        padding: 0.6rem 1.5rem;
      }
    }

    .trail-title {
      font-weight: 500;
      font-size: 1.15em;
    }

    .trail-description {

    }

    .trail-action {
      font-size: 0.85em;
      text-transform: uppercase;
      font-weight: bold;
      color: $color-gray;
    }
  }

  .dropdown-lang-switcher {
    right: $navbar-padding-x;
    @include media-breakpoint-down(sm) {
      right: 0;
    }

    & > .dropdown-toggle {
      padding: 0 1rem;
      .navbar-toggler-icon {
        background-image: url('../img/fontawesome/language-solid.svg');
        font-size: 1.6rem;
      }
    }

    @include media-breakpoint-up(lg) {
      & > .dropdown-toggle {
        display: none;
      }
      .dropdown-menu {
        display: block;
        top: -0.8rem;
        border: none;
        background-color: transparent;
        padding: 0;
        margin: 0;
        min-width: 300px;
        text-align: right;

        .dropdown-item {
          display: inline-block;
          width: auto;
          padding: 0.3rem 1rem;
          font-size: 0.85em;
          text-transform: uppercase;
          &:hover {
            background-color: transparent;
          }
        }
      }
    }
  }
}
