.tagline {
  font-size: 2em;
  font-weight: 500;
  line-height: 1.1;

  @include media-breakpoint-down(xs) {
    font-size: 1.5em;
  }
}

.tagline-align-left {
  display: inline-block;
  width: 1.4em;
}

.tagline-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  margin-left: 2em;
  margin-bottom: 1.3em;

  @include media-breakpoint-down(sm) {
    position: relative;
    font-size: 2em;
    margin-top: 4em;
    margin-left: 0.5em;
  }
}
